<template lang="pug">
.main-wrapper
  ProgressBar(v-if="waiting", mode="indeterminate")
  div(v-else)
    header.main-wrapper-header
      .p-grid.p-align-center
        .p-col-3
          Button.p-button-secondary.btn-back(
            icon="jam jam-chevrons-left",
            @click="$router.go(-1)"
          )
        .p-col-9.ta-right
          h1.text-header.text-secondary MedClub /
            router-link(:to="{ path: '/noticias' }") Notícias /
            b {{ model.id ? 'Editar' : 'Adicionar' }}

    Dialog(
      header="Adicionar Categoria",
      :visible.sync="dialog.adicionar",
      :modal="true"
    )
      form(@submit.prevent="adicionar()")
        InputText(v-model="novaCategoria", required)
        .ta-center.my-4
          Button(label="Adicionar")

    Dialog(
      header="Adicionar Anexo",
      :visible.sync="dialog.anexos",
      :modal="true"
    )
      form(@submit.prevent="adicionarAnexo()")
        .p-col-12
          label.form-label Título: *
          InputText(type="text", v-model="tituloNovoAnexo", required)

        .p-col-12
          div
            label.form-label Tipo: *

            div(style="display: flex; justify-content: space-between")
              Dropdown(
                v-model="tipo",
                :options="tipos",
                optionLabel="name",
                placeholder="Selecione o tipo",
                required,
                style="width: 80%"
              )
              Button(
                icon="jam jam-pencil",
                style="max-width: 160px",
                @click="$router.push('/anexos/editar-tipos')"
              )

        .p-col-12
          FileUpload(
            name="contents",
            :multiple="false",
            accept="*/*",
            @select="uploadAnexo",
            chooseLabel="Escolher Arquivo",
            cancelLabel="Cancelar"
            url=''
            :maxFileSize="1000000"
          )
            template(#empty)
              p Arraste e solte a imagem aqui para realizar upload
        .ta-center.my-4
          Button(label="Adicionar", type="submit")

    Dialog.dialogAnexo(
      header="Criar Álbum",
      :visible.sync="dialog.album",
      :modal="true"
    )
      .p-col-12.p-lg-6.mb-1
            label.form-label Título do Álbum: *
            InputText.p-inputtext-lg(
            type="text",
            v-model="nomeAlbum",
            required
      )
      ProgressBar(v-if='waitingAnexosAlbum' mode="indeterminate")
      FileUpload(
        v-if='!waitingAnexosAlbum'
        style="display: fixed",
        chooseLabel="Escolher Arquivo",
        cancelLabel="Cancelar",
        @select="uploadAnexoAlbuns",
        uploadLabel='Enviar'
        :multiple="true",
        url='/'
        @remove='removeImage'
        @before-send='beforeSend'
        accept="image/*",
        :maxFileSize="1000000"
      )
        template(#empty)
          p Arraste e solte a imagem aqui para realizar upload

      .p-grid.mt-2
      DataTable(:autoLayout='true' :value="anexosImagem", :selection.sync="selected", dataKey="id")
        Column(
          selectionMode="multiple",
          bodyStyle="text-align: center;",
          headerStyle="width: 3em"
        )
        Column(
          headerStyle="width: 8%;",
          bodyStyle="text-align: center;",
          field="id",
          header="Código"
        )

        Column(
          headerStyle="width: 15%;",
          bodyStyle="text-align: center;",
          field="attach",
          header="Imagem"
        )
          template(#body="props")
            img(
              v-bind:src='props.data.imagem_format'
              v-bind:alt="props.data.title",
              height="80",
              width="80"
            )

        Column(
          headerStyle="width: 75%;",
          bodyStyle="text-align: center;",
          field="title",
          header="Título"
        )

        Column(headerStyle="width: 10%; text-align: center")
          template(#header) Ações
          template(#body="props")
            .ta-center
              //- Button.p-button-raised.p-button-rounded.mr-1(
              //-     v-tooltip.top="'Visualizar'"
              //-     icon="jam jam-document"
              //-     @click="view(props.data.id)"
              //- )
              //- Button.p-button-raised.p-button-rounded.mr-1(
              //-     v-tooltip.top="'Editar'"
              //-     icon="jam jam-write"
              //-     @click="edit(props.data.id)"
              //- )
              Button.p-button-raised.p-button-rounded.p-button-danger(
                v-tooltip.top="'Apagar'",
                icon="jam jam-minus-circle",
                @click="dialogApagar(props.data.id)"
              )
        Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

      div(
        v-if="selected.length > 0",
        style="background-color: #fff; border-top: 1px solid #666; position: fixed; bottom: 0; left: 0; width: 100%; padding: 10px"
      )
        .p-col-12.ta-right
          h4(v-if="selected.length > 1") {{ selected.length }} imagens selecionadas
          h4(v-if="selected.length == 1") {{ selected.length }} imagem selecionada
          Button(
            v-tooltip.top="'Salvar'",
            label="Salvar",
            style="width: 160px",
            @click="criarAlbum(selected)"
          )

    form(@submit.prevent="handleSubmit()")
      ProgressBar(v-if="waiting", mode="indeterminate")
      div(v-else)
        Panel(:header="`${model.id ? 'Editar' : 'Adicionar'} notícias`")
          .p-grid.p-fluid.p-align-end
            .p-col-12.p-lg-6
              label.form-label Título: *
              InputText.p-inputtext-lg(
                type="text",
                v-model="model.title",
                required
              )

            .p-col-12.p-lg-6
              label.form-label Subtítulo:
              InputText.p-inputtext-lg(type="text", v-model="model.subtitle")

            .p-col-12
              label.form-label Sumário:
              TextArea(
                type="text",
                rows="5",
                cols="30",
                v-model="model.summary"
              )

            .p-col-12
              label.form-label Foto de Capa:
              //- <input type="file" accept="image/*" @change='myUploader' id="file-input">
              FileUpload(
                name="contents",
                type="file",
                @select="uploadCapa",
                chooseLabel="Escolher Arquivo",
                url=''
                mode='basic'
                cancelLabel="Cancelar",
                accept="image/*",
                :maxFileSize="1000000"
              )
                template(#empty)
                  p Arraste e solte a imagem aqui para realizar upload

            .p-col-12.mb-1.mt-1
              label.form-label Notícia Destaque:
              Checkbox(id="featured" name="featured" value="Chicago" v-model="featured")
              label(for="featured")  Notícia Destaque

            .p-col-12
              label.form-label Formato da notícia:
              .p-field-radiobutton
                RadioButton#text(
                  name="formato",
                  value="text",
                  v-model="formato"
                )
                label(for="text") Texto
              .p-field-radiobutton
                RadioButton#image(
                  name="formato",
                  value="image",
                  v-model="formato"
                )
                label(for="image") Imagem

            .p-col-12
              label.form-label Notícia: *
              Editor(
                v-if="formato === 'text'",
                v-model="model.contents",
                editorStyle="height: 320px"
              )
              FileUpload(
                v-else,
                name="contents",
                chooseLabel="Escolher Arquivo",
                cancelLabel="Cancelar",
                url="./upload.php",
                @upload="",
                :multiple="true",
                accept="image/*",
                :maxFileSize="1000000"
              )
                template(#empty)
                  p Arraste e solte a imagem aqui para realizar upload

            .p-col-12.p-lg-5
              label.form-label Anexos:
              Dropdown(v-model="anexo", :options="anexos", optionLabel="title", placeholder="Selecione o anexo", required)
                template(#option='props')
                    div(style='display: flex; flex-direction: row; align-items: center;')
                        img(
                            v-if='props.option.attachmentsType && props.option.attachmentsType.name == "Imagem"'
                            v-bind:src='props.option.imagem_format',
                            width="30",
                            height="30"
                        )
                        img(
                            v-if='props.option.attachmentsType && props.option.attachmentsType.name == "Documento"'
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png",
                            width="25",
                            height="30"
                        )
                        p.ml-1 {{ props.option.title }}

            .p-col-1
              Button(
                type="button",
                icon="jam jam-plus",
                style="max-width: 160px",
                @click="dialog.anexos = true"
              )

            .p-col-12.p-lg-5
              label.form-label Álbum:
              Dropdown(
                v-model="album",
                :options="albuns",
                optionLabel="name",
                placeholder="Selecione o álbum",
                required
              )
                template(#option='props')
                    div(style='display: flex; flex-direction: row; align-items: center;')
                        img(
                            v-if='props.option.cover_photo'
                            v-bind:src='props.option.cover_photo',
                            width="30",
                            height="30"
                        )
                        p.ml-1 {{ props.option.name }}

            .p-col-1
              Button(
                type="button",
                icon="jam jam-plus",
                style="max-width: 160px",
                @click="dialog.album = true"
              )

            .p-col-12.p-lg-5
              label.form-label Categoria: *
              Dropdown(
                v-model="categoria",
                :options="categorias",
                optionLabel="name",
                placeholder="Selecione a categoria",
                required
              )

            .p-col-1
              Button(
                type="button",
                icon="jam jam-plus",
                style="max-width: 160px",
                @click="dialog.adicionar = true"
              )

            .p-col-6
              label.form-label Data de publicação: *
              .p-inputgroup
                Calendar(
                  v-model="data",
                  placeholder="Selecione a data de publicação da notícia",
                  dateFormat="dd/mm/yy",
                  required
                )
                Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='data = null; applyFilters()')

            .p-col-12.p-lg-6.ta-left
              p (*) Itens obrigatórios

            .p-col-12.p-lg-6.ta-right
              Button(
                v-if="!waitingSalvar",
                label="Salvar",
                type="submit",
                style="max-width: 160px"
              )
              ProgressBar(v-if="waitingSalvar", mode="indeterminate")
</template>

<style lang="scss">
.dialogAnexo {
  width: 60%;
  height: 100%;
  background-color: #fff;
}
@media (max-width: 768px) {
    .dialogAnexo {
        width: 100%;
    }
}
</style>

<script>
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import TextArea from "primevue/textarea";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import Noticias from "../../middleware/controllers/Noticias";
import Paginator from 'primevue/paginator';
import RadioButton from "primevue/radiobutton";
import Editor from "primevue/editor";
import FileUpload from "./FileUpload.vue";
import Column from "primevue/column";
import Tooltip from 'primevue/tooltip'
import Checkbox from 'primevue/checkbox';
import DataTable from "primevue/datatable";
import Calendar from "primevue/calendar";
import { converteDataParaFront, converteDataParaAPI } from "../../utils.js";

export default {
  data() {
    return {
      waiting: false,
      waitingSalvar: false,
      model: {},
      categorias: [],
      anexos: [],
      albuns: [],
      selected: [],
      formato: "text",
      nomeAlbum: '',
      data: "",
      categoria: {},
      anexo: {},
      album: {},
      paginator: {
          page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: 10,
					count: 0
			},
      featured: false,
      tituloNovoAnexo: "",
      anexosLink: [],
      anexosImagem: [],
      anexosImagemAlbum: [],
      waitingAnexosAlbum: false,
      tipos: [],
      tipo: "",
      fotoCapa: {},
      dialog: {
        apagar: false,
        adicionar: false,
        anexos: false,
        album: false,
      },
      novaCategoria: "",
    };
  },
  components: {
    Dialog,
    Column,
    DataTable,
    ProgressBar,
    Button,
    InputText,
    ProgressSpinner,
    Panel,
    TextArea,
    Dropdown,
    RadioButton,
    Checkbox,
    Editor,
    FileUpload,
    Tooltip,
    Calendar,
    Paginator,
  },
  directives: { tooltip: Tooltip },
  created() {
    this.waiting = true;

    //Login na nova API
    Noticias.login().then((result) => {
      this.tokenAPI = result;

      Noticias.getCategories(this.tokenAPI).then((categorias) => {
        this.categorias = categorias.rows;

        // Formulário para editar notícias
        let id = 0;
        if (this.$route.params.id !== undefined) {
          if (!isNaN(this.$route.params.id))
            id = parseInt(this.$route.params.id);

          Noticias.getNewsById(id, this.tokenAPI).then((response) => {
            this.model = response;
            this.data = converteDataParaFront(this.model.publish_date);
            this.categoria = this.categorias.filter(
              (categoria) => categoria.id === this.model.categories.id
            )[0];
            this.waiting = false;
          });
        } else {
          this.waiting = false;
        }
      });
      Noticias.getAttachments(this.tokenAPI, this.paginator.page).then((response) => {
        if (!response) this.anexos = []
        else {
          // Ordena a lista pelo id em ordem decrescente
          // response.rows.sort((a, b) => {
          //     if(a.id < b.id) return 1
          //     else return -1
          // })
          response.rows.forEach(element => {
              element.imagem_format = 'https://' + element.attach
              if(element.attachmentsType && element.attachmentsType.name == 'Imagem') {
                  this.anexosImagem.push(element);
              }
          })

          this.paginator.count = response.total
          this.anexos = response.rows;
        }
        // this.waiting = false
      });
      Noticias.getAlbuns(this.tokenAPI).then((response) => {
        if (!response) this.albuns = [];
        else {
          // // Ordena a lista pelo id em ordem decrescente
          // response.rows.sort((a, b) => {
          //     if(a.id < b.id) return 1
          //     else return -1
          // })
          this.albuns = response.rows;
        }
        // this.waiting = false
      });

      Noticias.getAttachmentTypes(this.tokenAPI).then((response) => {
        if (!response) this.tipos = [];
        else this.tipos = response.rows;
      });
    });
  },
  methods: {
    adicionar() {
      this.waiting = true;
      Noticias.saveCategory(this.novaCategoria, this.tokenAPI).then(
        (response) => {
          this.dialog.adicionar = false;
          if (!response)
            this.$toast.error("Não foi possível adicionar a categoria.", {
              duration: 3000,
            });
          else {
            this.novaCategoria = "";
             Noticias.getCategories(this.tokenAPI).then((categorias) => {
                this.categorias = categorias.rows;
             })

          }
          this.waiting = false;
        }
      );
    },
    uploadAnexo(event) {
      // console.log("uploadEvent", event);
      var image = event.originalEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);


        reader.onload = (e) => {
            this.anexosLink.push({
                extension: image.name.split('.').pop(),
                file: e.target.result,
                destinationFolder: 'site/anexos'
            });
        };
      },
      // removeImage(event) {
      //     console.log(event);
      // },
      async convertBase64(file) {
            return await new Promise((resolve) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.readAsDataURL(file)
            })
      },
      onPage(event) {
        this.paginator.page = event.page + 1;
         Noticias.getAttachments(this.tokenAPI, this.paginator.page).then((response) => {
            if (!response) this.anexos = [];
            else {
              // Ordena a lista pelo id em ordem decrescente
              response.rows.sort((a, b) => {
                  if(a.id < b.id) return 1
                  else return -1
              })
              this.anexosImagem = [];
              response.rows.forEach(element => {
                  element.imagem_format = 'https://' + element.attach
                  if(element.attachmentsType && element.attachmentsType.name == 'Imagem') {
                      this.anexosImagem.push(element);
                  }
              })
            }

        });
      },
      beforeSend() {
          // console.log(event);
          this.waitingAnexosAlbum = true;

            Noticias.upload(
            {
                files: this.anexosImagemAlbum
            },
            this.tokenAPI
                ).then(response => {

                    // this.waitingAnexosAlbum = false;
                    // console.log('resposta', response);
                    var data = [];
                    response.forEach(element => {
                        data.push({
                            title: element.key,
                            attach: element.url,
                            attachmentsType: {
                                id: "6"
                            }
                        })
                    });
                    Noticias.saveAttachment(data, this.tokenAPI).then(response =>  {
                    if(! response)
                        this.$toast.error('Não foi possível adicionar o anexo.', { duration: 3000 })
                    else {
                        Noticias.getAttachments(this.tokenAPI, this.paginator.page, 'id-desc').then((response) => {
                            if (!response) this.anexos = [];
                            else {
                            // Ordena a lista pelo id em ordem decrescente
                            response.rows.sort((a, b) => {
                                if(a.id < b.id) return 1
                                else return -1
                            })
                            this.anexosImagem = [];
                            response.rows.forEach(element => {
                                element.imagem_format = 'https://' + element.attach
                                if(element.attachmentsType && element.attachmentsType.name == 'Imagem') {
                                    this.anexosImagem.push(element);
                                }
                            })

                            //  this.paginator.page = Math.round(response.total/response.perPage);
                             this.anexos = response.rows;
                             this.waitingAnexosAlbum = false;
                            }

                        });
                    }

                })
            });

      },
     uploadAnexoAlbuns(event) {

        event.originalEvent.target.files.forEach(async imagem => {
            // console.log(imagem);
            // img = await this.convertBase64(imagem)
            this.anexosImagemAlbum.push({
                file: await this.convertBase64(imagem),
                destinationFolder: "site/anexos",
                extension: imagem.name.split('.').pop()
            });
        })
    },
    criarAlbum(dados) {
        // console.log(dados);
        if(this.nomeAlbum) {
            var webAttachments = [];
            dados.forEach(result => {
                webAttachments.push({
                    attach: result.attach,
                    title: result.title,
                    sequence: result.sequence,
                })
            })
            var dataSend = {
                name: this.nomeAlbum,
                cover_photo: 'https://' + dados[0].attach,
                webAttachments: webAttachments
            };
            // console.log(dataSend);
            Noticias.saveAlbuns(dataSend
            , this.tokenAPI)
            .then(() => {
                // console.log(response);
                this.dialog.album = false;
                this.nomeAlbum = '';
                this.selected = [];
                 Noticias.getAlbuns(this.tokenAPI).then((response) => {
                    if (!response) this.albuns = [];
                    else {
                    // // Ordena a lista pelo id em ordem decrescente
                    // response.rows.sort((a, b) => {
                    //     if(a.id < b.id) return 1
                    //     else return -1
                    // })
                    this.albuns = response.rows;
                    }
                    // this.waiting = false
                });
            })
        } else {
            this.$toast.error('Digite o nome do Álbum.', { duration: 3000 })
        }

    },
    async adicionarAnexo() {
        if(this.anexosLink.length > 0) {
            await Noticias.upload({
                files: this.anexosLink
            }, this.tokenAPI)
            .then(response => {
                // console.log(response);
                var data = {};
                response.forEach(element => {
                    data = {
                        title: this.tituloNovoAnexo,
                        attach: element.url,
                        attachmentsType: {
                            id: this.tipo.id
                        }
                    }
                });

                Noticias.saveAttachment(data, this.tokenAPI).then(response =>  {
                    this.dialog.anexos = false
                    if(! response)
                        this.$toast.error('Não foi possível adicionar o anexo.', { duration: 3000 })
                    else {
                        this.tituloNovoAnexo = ''
                        Noticias.getAttachments(this.tokenAPI).then((response) => {
                            if (!response) this.anexos = [];
                            else {
                            // // Ordena a lista pelo id em ordem decrescente
                            // response.rows.sort((a, b) => {
                            //     if(a.id < b.id) return 1
                            //     else return -1
                            // })
                            response.rows.forEach(element => {
                                element.imagem_format = 'https://' + element.attach
                            })
                            this.anexos = response.rows;
                            }
                            // this.waiting = false
                        });
                    }

                })
            })
        }
    },
    uploadCapa(event) {
      var image = event.originalEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);

      this.fotoCapa.extensao = image.name.split('.').pop();

      reader.onload = (e) => {
        this.fotoCapa.data = e.target.result;
      };
    },
    handleSubmit() {
      if(this.model.contents) {
          if(Object.keys(this.categoria).length > 0) {
      this.waitingSalvar = true;
      var id = '';
      let dataSend = Object.assign({}, this.model);
      const action = this.model.id ? "edit" : "save";
      const message =
        action === "save"
          ? "Notícia adicionada com sucesso."
          : "Notícia atualizada com sucesso.";

      // Usuário que cria a notícia é o newsCreator
      dataSend.user_id = "2";


      dataSend.categories = {
        id: this.categoria.id,
      };

      let featured = 0;
      if(this.featured) {
        featured = 1;
      } else {
        featured = 0;
      }

      dataSend.featured = featured;
      dataSend.albums = this.album.id;


      if (action === "save")
        dataSend.publish_date =
          this.data.getFullYear() +
          "-" +
          (this.data.getMonth() + 1) +
          "-" +
          this.data.getDate();
      else dataSend.publish_date = converteDataParaAPI(this.data);

      // console.log(action);
      Noticias[action](dataSend, this.tokenAPI).then((response) => {
        if (!response)
          this.$toast.error("Não foi possível adicionar a notícia.", {
            duration: 3000,
          });
        else {
          id = response.id;
          // console.log('criada', id);

          if(this.fotoCapa.data) {
              Noticias.upload(
                {
                files: [
                    {
                    extension: this.fotoCapa.extensao,
                    destinationFolder: "site/capa",
                    file: this.fotoCapa.data,
                    },
                ],
                },
                this.tokenAPI
            ).then((response) => {
                Noticias.edit({
                    id: this.model.id ? this.$route.params.id : id,
                    cover_photo: 'https://' + response[0].url
                }, this.tokenAPI)
                .then(() => {
                    // console.log(response);
                     this.$toast.success(message, { duration: 3000 });
                     this.waiting = false;
                     this.waitingSalvar = false;
                     this.$router.push('/noticias')
                })
            });
          } else {
               this.$toast.success(message, { duration: 3000 });
               this.waiting = false;
               this.waitingSalvar = false;
               this.$router.push('/noticias')
          }

        }
      });
      //   }
          } else {
              this.$toast.error("Escolha a categoria da notícia.", {
                duration: 3000,
              });
          }
        } else {
            this.$toast.error("Digite o conteúdo da notícia.", {
                duration: 3000,
            });
        }
     }
  },
};
</script>
